const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_Oc2WZsLqc',
  aws_user_pools_web_client_id: 'qng3fbqfk0qunm9mhea3a449u',
  oauth: {
    domain: 'dev-ridecare.auth.eu-central-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:4200/admin',
    redirectSignOut: 'http://localhost:4200/login',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default awsmobile;
