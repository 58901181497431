import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTE_ACCESS_DENIED, ROUTE_ADMIN, ROUTE_LOGIN } from './app-routing.constants';

const routes: Routes = [
  {
    path: ROUTE_ACCESS_DENIED,
    loadChildren: () => import('./error/error.module').then((module) => module.ErrorModule),
  },
  {
    path: ROUTE_ADMIN,
    loadChildren: () => import('./admin/admin.module').then((module) => module.AdminModule),
  },
  {
    path: ROUTE_LOGIN,
    loadChildren: () => import('./login/login.module').then((module) => module.LoginModule),
  },
  {
    path: '**',
    redirectTo: ROUTE_ADMIN,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
