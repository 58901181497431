import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Auth } from '@aws-amplify/auth';

import { AppModule } from './app/app.module';
import awslocal from './configs/aws/aws-exports-local';
import awsdev from './configs/aws/aws-exports-dev';
import awsprod from './configs/aws/aws-exports-prod';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  Auth.configure({ ...awsprod });
} else if (environment.development) {
  Auth.configure({ ...awsdev });
} else {
  Auth.configure({ ...awslocal });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
