import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/assets/')) {
      return next.handle(req);
    }
    return from(Auth.currentSession()).pipe(
      switchMap((auth: any) => {
        const jwt = auth.accessToken.jwtToken;

        let authReq;
        authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        return next.handle(authReq);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
