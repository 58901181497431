const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_5oSMCSEoP',
  aws_user_pools_web_client_id: '6l2qg4psid0r6ufjm192hmp9gt',
  oauth: {
    domain: 'prod-ridecare.auth.eu-central-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://support.bosch-ridecare.com/admin',
    redirectSignOut: 'https://support.bosch-ridecare.com/login',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default awsmobile;
